/**
 * 在主框架内显示
 * 路由配置说明
 * {
      path: '/dir-demo-info',    // 页面地址（唯一）
      name: 'dir-demo-info',     // 页面名称（唯一）
      hidden: false,              // 隐藏（不展示在侧边栏菜单）
      meta: {
          title: '用户管理',       // 页面标题
          icon: 'yonghuguanli',  // 页面图标
          cache: true,          // 页面是否进行缓存 默认true
          link: false,           // 页面是否是外链 默认false
          frameSrc: false,       // 页面是否是内嵌 默认false
          requiresAuth: true,   // 页面是否是需要登录 默认true
      },
      component: () => import('@/views/sys/users/dir-users-info.vue'),// 懒加载页面组件
   }
 *
 */

const frameIn = [
  {
    path: '/',
    name: 'layout',
    redirect: {name: 'index'},
    component: () => import('@/layout/index.vue'),
    /********************children 建议最多 再加一级children  否则侧边栏体验不好*********************/
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          cache: true,
          title: '首页',
          icon: 'home-filling',
          requiresAuth: true,
          permission: 'dashboard'
        },
        component: () => import('@/views/dashboard/index.vue'),
        hidden: true,
      },
    ]
  },
];

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/login.vue'),
  },
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error/401.vue'),
    meta: {
      title: '401',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/error/404.vue'),
    meta: {
      title: '404',
    },
  }
]


// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]