import { createApp } from 'vue'
import App from './App.vue'
// import pinia from '@/stores'
import { createPinia } from 'pinia'
import router from "@/router";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import {createFromIconfontCN} from '@ant-design/icons-vue';

// 创建 IconFont
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4441500_0u7z48f0yvup.js',
});

const pinia = createPinia()
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(Antd);

// 注册全局组件
app.component('IconFont', IconFont);

app.mount('#app');

