import service from './request'

export function authLogin(param) {
  return service.post('/users/login', param)
}
export function authLogout(param) {
  return service.post('/users/logout', param)
}
export function getUserInfo() {
  return service.get('/users/info')
}

export function getUserList(param) {
  return service.get('/users', param)
}

export function getUserDetail(id) {
  return service.get('/users/' + id)
}

export function saveUser(param) {
  return service.post('/users', param)
}

export function updateUser(id, param) {
  return service.put('/users/'+id, param)
}

export function deleteUser(id) {
  return service.delete('/users/'+id)
}

export function getUserLogList(param) {
  return service.get('/userLogs', param)
}