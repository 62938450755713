import {ref} from 'vue';
import {defineStore} from 'pinia';

export const useMenuStore = defineStore('menu', () => {

    const openKeys = ref([]);   // 展开的菜单组
    const currentRoute = ref('/');   // 当前路由path
    const memberFeedbackNum = ref(false);   // 用户反馈路由角标展示

    function currentRouteSet(path) {
        currentRoute.value = path;
    }

    function memberFeedbackNumSet(num) {
        memberFeedbackNum.value = num;
    }

    function openKeysSet(path) {
        if (Array.isArray(path)) {
            openKeys.value = path;
        } else {
            const index = openKeys.value.findIndex(v => v === path)
            if (index > -1) {
                openKeys.value.splice(index, 1);
            } else {
                openKeys.value.push(path);
            }
        }
    }

    return {
        openKeys,
        currentRoute,
        memberFeedbackNum,
        openKeysSet,
        currentRouteSet,
        memberFeedbackNumSet,
    };
});
