<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import {ref} from "vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {getSitePromptData} from "@/api/common";
import {useMenuStore} from "@/stores/menu";

const locale = ref(zhCN)
const menuStore = useMenuStore();

// 修改日期组件为中文
dayjs.locale('zh-cn');

// 解决报错: ResizeObserver loop completed with undelivered notifications. (调整浏览器界面的时候抛出异常,)
// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
// 解决报错: ResizeObserver loop completed with undelivered notifications. (调整浏览器界面的时候抛出异常,)

const getPromptData = () => {
	getSitePromptData().then(res => {
		if (res.code === 200) {
			menuStore.memberFeedbackNumSet(res.data.member_feedback_unread_count)
		}
	})
}
getPromptData()
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/common.scss';

</style>
