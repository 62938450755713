import {createRouter, createWebHashHistory} from 'vue-router'
import routes from "./routes.js";
import setting from "@/setting";
import dbUtils from '@/utils/strotage.js'
import {getToken} from "@/utils/common";
import {useAuthStore} from "@/stores/auth";
import {useMenuStore} from "@/stores/menu";
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.scss'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
  routes, // `routes: routes` 的缩写
})
// 路由守卫
let registerRouteFresh = true // 定义标识，记录路由是否添加
router.beforeEach(async (to, from, next) => {
  NProgress.start() // start progress bar

  const authStore = useAuthStore()
  // 使用 useMenuStore 获取菜单存储实例
  const menuStore = useMenuStore()
  menuStore.currentRouteSet(to.path);

  const isLoggedIn = getToken()

  const dbPermissions = dbUtils.get('menus')
  // 如果首次或者刷新界面，next(...to, replace: true)会循环遍历路由，如果to找不到对应的路由那么他会再执行一次beforeEach((to, from, next))直到找到对应的路由，
  // 我们的问题在于页面刷新以后异步获取数据，直接执行next()感觉路由添加了但是在next()之后执行的，所以我们没法导航到相应的界面。这里使用变量registerRouteFresh变量做记录，直到找到相应的路由以后，把值设置为false然后走else执行next(),整个流程就走完了，路由也就添加完了。
  if (registerRouteFresh && isLoggedIn && dbPermissions && dbPermissions.length) {

    await addPermissionsRoute(dbPermissions)

    next(to.path)
    registerRouteFresh = false
    return;
  }

  // 检查是否需要登录
  if (to.meta.requiresAuth) {
    // 检查用户是否已经登录
    if (isLoggedIn) {
      // 已经登录 访问登录页则直接跳到主页
      if (to.name === 'login') {
        next('/');
        NProgress.done()
        return;
      }
      if (authStore.user === null) {
        await authStore.getUserDetail()
      }
      const permissionList = authStore.user && authStore.user.permissions || [];
      // 如果还未找到缓存的权限数组则返回登录页
      if (!permissionList || !permissionList.length) {
        // 清空所有缓存数据
        dbUtils.clear()
        // 重定向到登录页
        next(`/login?redirect=${to.path}`)
        NProgress.done()
        return;
      }
      if (router.hasRoute(to.name)) {
        if (authStore.options === null) {
          await authStore.getOptionList()
        }
        const index = menuStore.openKeys.findIndex(v => v === to.matched[1].path)
        if (index === -1) {
          menuStore.openKeysSet(to.matched[1].path);
        }
        // 有权限直接访问
        next();
        NProgress.done()
        return;
      }

      // 无权限则重定向到401
      next({name: '401'});
      NProgress.done()
      return;
    }
    // 未登录
    // 清空所有缓存数据
    dbUtils.clear()
    // 重定向到登录页
    return next(`/login?redirect=${to.path}`)
  }
  // 无需登录的页面 直接访问即可
  next();
  NProgress.done()
});

router.afterEach((to) => {
  window.document.title = to.meta.title + " - " + setting.website.name;
  NProgress.done()
})

router.onError((error) => {
  // 在这里处理路由错误
  console.error('路由错误:', error);
});

export default router

function buildTreeRouter(arr) {
  arr.forEach(item => {
    if (item.pageUrl) {
      item.component = loadView(item.pageUrl);
    }
    if (item.path) {
      item.path = '/' + item.path.replace('/', "")
    }
    delete item.pageUrl
    if (item.children && item.children.length) {
      buildTreeRouter(item.children)
    }
  });
}

// 静态import作为回退方案
function loadView(view) {
  view = view.replace('/', "")
  return () => import(`@/views/${view}.vue`);
}

export async function setAsyncRoutes() {

  const authStore = useAuthStore()
  const permissions = authStore.user.permissions
  // 将菜单缓存
  dbUtils.set('menus', permissions)

  const layoutRoute = await addPermissionsRoute(permissions)

  let accessedRouters = layoutRoute.children.filter(e => !e.hidden)

  // 设置打开菜单
  let openKeys = accessedRouters.map(v => v.path)
  const menuStore = useMenuStore()
  menuStore.openKeysSet(openKeys)
}

function addPermissionsRoute(routeList) {

  buildTreeRouter(routeList)

  const indexRoute = [{
    path: '/',
    name: 'index',
    meta: {
      cache: true,
      title: '首页',
      icon: 'home-filling',
      requiresAuth: true,
      permission: 'dashboard'
    },
    component: () => import('@/views/dashboard/index.vue'),
    hidden: true,
  }]
  const redirectRoute = [{
    path: '/redirect/:path(.*)/:_origin_params(.*)?',
    name: 'Redirect',
    hidden: true,//不展示在侧边栏菜单
    meta: {
      title: '重定向',
    },
    component: () => import('@/views/redirect'),
  }]
  let layoutRoute = {
    path: '/',
    name: 'layout',
    redirect: {name: 'index'},
    component: () => import('@/layout/index.vue'),
    children: [
      ...indexRoute,
      ...routeList,
      ...redirectRoute,
    ]
  }
  // 删除路由
  router.removeRoute('layout')

  //构建完路由后，利用router.addRoute()方法添加路由
  router.addRoute(layoutRoute)

  return layoutRoute
}
